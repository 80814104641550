import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { IonicModule, IonicRouteStrategy, IonRouterOutlet } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule,HttpHeaders,HttpErrorResponse,HttpRequest, } from '@angular/common/http';
import {NativePageTransitions} from '@ionic-native/native-page-transitions';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
     
    }

    ),
    AppRoutingModule,
    HttpClientModule,

  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NativePageTransitions,
    InAppBrowser
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
