import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AlertController,
  LoadingController,
  ActionSheetController,
  NavController,
  IonRouterOutlet,
  ToastController,
  IonAvatar,
} from "@ionic/angular";
import { Platform } from "@ionic/angular";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpRequest,
} from "@angular/common/http";
import { Httprequest } from "./models/httprequest";
import { Push, PushObject, PushOptions } from "@ionic-native/push";
import { Device } from "@ionic-native/device";
import { Observable } from "rxjs";
import { ChatService } from "./services/chat.service";
import { EventEmitter, Output } from "@angular/core";
import { StatusBar } from "@ionic-native/status-bar";
import {
  NativePageTransitions,
  NativeTransitionOptions,
} from "@ionic-native/native-page-transitions";
import { Location } from "@angular/common";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  providers: [Push, Device, IonRouterOutlet, ChatService, StatusBar],
})
export class AppComponent implements OnInit {
  @Output() notificationEvent = new EventEmitter<any>();

  isLogin = false;

  appConfig = {
    "api_url": "https://dranis.aipipis.com/en/api",
    //"api_url": "http://dranis-crm-web.local/en/api",
    version: "0.0.17",
  };

  googleApiKey = 'AIzaSyB9okV4xXsrWFhXb_wbn3cvcumgzKAXmcs';

  api_url = this.appConfig.api_url;
  userdata = {
    name: localStorage.name != null ? localStorage.name : "",
    token: localStorage.token != null ? localStorage.token : "",
    default_company_id:
      localStorage.default_company_id != null
        ? localStorage.default_company_id
        : "",
    login_user_id: localStorage.login_user_id != null ? localStorage.login_user_id : "",
    mobile: localStorage.mobile != null ? localStorage.mobile : "",
  };

  chatdetailsData = [];
  isloadMore = false;
  swipe = true;

  public appPages = [
    {
      title: "Homepage",
      url: "/home",
      icon: "home",
      iconimg: "../assets/home-icon.svg",
      iconimgactive: "../assets/home-active-icon.svg",
    },
    {
      title: "Profile",
      url: "/profile",
      icon: "person",
      iconimg: "../assets/user-icon.svg",
      iconimgactive: "../assets/user-active-icon.svg",
    },
    {
      title: "Settings",
      url: "/setting",
      icon: "cog",
      iconimg: "../assets/setting-icon.svg",
      iconimgactive: "../assets/setting-active-icon.svg",
    },
    {
      title: "Add Request",
      url: "/request-add",
      icon: "cog",
      iconimg: "../assets/setting-icon.svg",
      iconimgactive: "../assets/setting-active-icon.svg",
    },
  ];

  pushEnabled = false;
  routePathParam: Observable<string>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public httpClient: HttpClient,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public actionSheetController: ActionSheetController,
    private push: Push,
    private device: Device,
    private platform: Platform,
    private chatService: ChatService,

    public nativePageTransitions: NativePageTransitions,
    public navCtrl: NavController,
    public location: Location,
    private statusBar: StatusBar,
    //    private routerOutlet : IonRouterOutlet,
    public toastController: ToastController
  ) {
    this.initializeApp();
    // this.chatService.notificationEvent.subscribe((data)=>{
    //   this.swipe = data;
    //   console.log(data);
    // });
  }

  ngOnInit() {
    if (this.device.platform == "iOS") {
      this.statusBar.overlaysWebView(false);
    }
    //this.routerOutlet.swipeGesture = true;

    // var url = window.location.href.split("/");
    // this.page = url[3];
  }

  ionViewWillEnter() {}

  initializeApp() {
    this.checkLoginToken();
  }

  // getSwipe(swipe){
  //   this.swipe = swipe;
  //   console.log(swipe);
  // }

  public async configurePushRegister() {
    this.platform.ready().then(() => {
      // console.log('the platform is ready');
      if (this.device.platform == "Android" || this.device.platform == "iOS") {
        // to check if we have permission
        this.push.hasPermission().then((res: any) => {
          if (res.isEnabled) {
            // console.log('We have permission to send push notifications');
            this.pushEnabled = true;
          } else {
            // console.log('We do not have permission to send push notifications');
            // this.pushEnabled = false;
            this.pushEnabled = true;
          }
        });

        // to initialize push notifications
        const options: PushOptions = {
          android: {},
          ios: {
            alert: "true",
            badge: true,
            sound: "false",
          },
          windows: {},
          browser: {
            pushServiceURL: "http://push.api.phonegap.com/v1/push",
          },
        };

        const pushObject: PushObject = this.push.init(options);

        pushObject.on("notification").subscribe((notification: any) => {
          // console.log('Received a notification', notification)

          var url = window.location.href.split("/");
          if (url[3] == "chatroom-detail") {
            this.presentToast_default(
              notification["title"],
              notification["message"]
            );
            this.notificationEvent.emit("asd");
          } else {
            this.presentAlert_default(
              notification["title"],
              notification["message"]
            );
          }

          // this.getMsg(false,url[4]);
        });

        pushObject.on("registration").subscribe((registration: any) => {
          //console.log('Device registered', registration)
          localStorage.pushRegisterTkn = registration.registrationId;
          localStorage.pushRegisterType = registration.registrationType;

          //insert/update database
          var tknLength = registration.registrationId.length;
          if (tknLength > 0) {
            if (
              localStorage.account_id != null &&
              localStorage.account_id > 0
            ) {
              this.updatePushNotificationUser(
                registration.registrationId,
                registration.registrationType,
                localStorage.account_id
              );
            } else {
              //insert with userId 0
              this.updatePushNotificationUser(
                registration.registrationId,
                registration.registrationType,
                "0"
              );
            }
          }
        });

        pushObject.on("error").subscribe((error) => {
          console.error("Error with Push plugin", error);
          this.presentAlert_default("Error with Push plugin", error);
        });
      }
    });
  }

  updatePushNotificationUser(pushToken, pushType, login_user_id) {
    this.httpClient
      .post(
        this.api_url + "/appUpdatePushNotificationUser",
        {
          pushToken: pushToken,
          pushType: pushType,
          device_manufacturer: this.device.manufacturer,
          device_id: this.device.uuid,
          device_model: this.device.model,
          device_platform: this.device.platform,
          pushEnabled: this.pushEnabled,
          login_user_id: login_user_id,
        },
        { observe: "body" }
      )
      .subscribe(
        (data) => {
          if (data["status"] == "OK") {
            localStorage.chatNote = data["result"]["data"]["chat_enabled"];
            localStorage.pushNote = data["result"]["data"]["push_enabled"];
            localStorage.vouchNote = data["result"]["data"]["voucher_enabled"];
          } else {
            this.presentAlert_default(
              data["status"],
              data["result"],
              "../../assets/attention-icon.svg"
            );
          }
        },
        (error) => {
          this.presentAlert_default(
            "ERROR",
            "Connection Error",
            "../../assets/attention-icon.svg"
          );
        }
      );
  }

  public checkLoginToken = function () {
    if (localStorage.token != null) {
      console.log("Token is exits, check login token");
      //check token is valid or not
      // this.presentLoading();
      this.httpClient
        .get(
          this.api_url +
            "/checkLoginToken/" +
            localStorage.login_user_id +
            "/" +
            localStorage.token
        )
        .subscribe(
          (data2) => {

            // console.log("DATA",data2);
            // this.loadingController.dismiss();
            if (data2.result.is_login == true) {
              //console.log("Verified, can proceed");
              this.isLogin = true;
              //this.navCtrl.navigateRoot('home',{animationDirection:'forward'});
            } else {
              console.log("Invalid token 1");
              //localStorage.clear();
              this.isLogin = false;
              this.router.navigate(["login"]);
            }
          },
          (error) => {
            console.log("Invalid token 2");
            // this.loadingController.dismiss();
            //localStorage.clear();
            this.isLogin = false;
            this.router.navigate(["login"]);
          }
        );
    } else {
      console.log("Invalid token 3");
      //localStorage.clear();
      this.isLogin = false;
      this.router.navigate(["login"]);
    }
  };


  toLogout() {
    this.isLogin = false;
    localStorage.isLogin = 0;
    localStorage.clear();
    this.router.navigate(["login"]);
  }

  setLoginStatus(status) {
    this.isLogin = status;
  }

  setUserData(name, token, company_id, login_user_id, mobile) {
    this.userdata = {
      name: name,
      token: token,
      default_company_id: company_id,
      login_user_id: login_user_id,
      mobile: mobile,
    };
  }

  public async presentLoading(timer=2000) {
    const loading = await this.loadingController.create({
      duration: timer,
    });
    // console.log("Loading show");
    await loading.present();
  }

  public async stopLoading(countdownTime) {
    setTimeout(() => {
      // console.log("Loading hide");
      this.loadingController.dismiss();
    }, countdownTime);
  }

  public async presentAlert_default(title, content, iconimg = "") {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class alearboxclass",
      header: "",
      subHeader: "",
      message:
        '<img src="' +
        iconimg +
        '"><h2>' +
        title +
        "</h2><p>" +
        content +
        "</p>",
      buttons: [
        {
          text: "Close",
          cssClass: "confirmbutton",
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  public async presentToast_default(title, content) {
    const toast = await this.toastController.create({
      cssClass: "my-custom-class alearboxclass",
      color: "dark",
      duration: 2000,
      header: title,
      position: "top",
      message: content,
    });

    await toast.present();
  }

  public async confirmAction_default(
    title,
    content,
    iconimg = "",
    tobeSubmit,
    url
  ) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class alearboxclass confirmActionalearboxclass",
      header: "",
      subHeader: "",
      message:
        '<img src="' +
        iconimg +
        '"><h2>' +
        title +
        "</h2><p>" +
        content +
        "</p>",
      buttons: [
        {
          text: "Cancel",
          cssClass: "colosebutton",

          handler: () => {},
        },
        {
          text: "Confirm",
          cssClass: "confirmbutton",
          handler: () => {
            this.presentLoading();
            this.httpClient.post(this.api_url + url, tobeSubmit).subscribe(
              (data: Httprequest) => {
                if (data.status == "OK") {
                  this.loadingController.dismiss();
                  location.reload();
                } else {
                  this.loadingController.dismiss();
                  this.presentAlert_default(
                    data["status"],
                    data["result"],
                    "../../assets/attention-icon.svg"
                  );
                }
              },
              (error) => {
                this.loadingController.dismiss();
                this.presentAlert_default(
                  "ERROR",
                  "Connection Error",
                  "../../assets/attention-icon.svg"
                );
              }
            );
          },
        },
      ],
    });

    await alert.present();
  }

  public async slidePage(navigate) {
    //let options : NativeTransitionOptions = {
    // direction:'left',
    //duration: 300,
    //slowdownfactor: -1,
    //iosdelay: 50,
    // androiddelay: 50,
    //fixedPixelsBottom:0,
    //  fixedPixelsTop:0

    //}
    //  this.nativePageTransitions.slide(options);
    this.navCtrl.navigateForward(navigate);
  }

  public async goBack(navigate) {
    //   let options : NativeTransitionOptions = {
    //     direction: 'right',
    //     duration: 400,
    //     slowdownfactor:-1,
    //     iosdelay:50,
    //     androiddelay: 150
    //   }
    //  this.nativePageTransitions.slide(options);
    this.navCtrl.back();
    //  this.location.back();
  }

  setFirstLetterToUppercase(string: string): string {
    var slug = string.split(".");
    var final = "";

    for (var i = 0; i < slug.length; i++) {
      if (i == 0) {
        var temp = slug[i].trim();
        temp = temp.charAt(0).toUpperCase() + temp.slice(1);

        slug[i] = temp;

        final += slug[i];
      } else if (i > 0) {
        var temp = slug[i].trim();
        temp = temp.charAt(0).toUpperCase() + temp.slice(1);

        slug[i] = temp;
        final += ". " + slug[i];
      }
    }
    return final;
  }
}
