import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'trade',
    loadChildren: () => import('./trade/trade.module').then( m => m.TradePageModule)
  },
  {
    path: 'trade/:voucher_id/:type',
    loadChildren: () => import('./trade/trade.module').then( m => m.TradePageModule)
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'wishlist/:sourceType',
    loadChildren: () => import('./wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'wishlist/:sourceType/:restaurant_id',
    loadChildren: () => import('./wishlist/wishlist.module').then( m => m.WishlistPageModule)
  },
  {
    path: 'vouchers',
    loadChildren: () => import('./vouchers/vouchers.module').then( m => m.VouchersPageModule)
  },
  {
    path: 'qr',
    loadChildren: () => import('./qr/qr.module').then( m => m.QrPageModule)
  },
  {
    path: 'voucher-detail/:voucher_id/:type',
    loadChildren: () => import('./voucher-detail/voucher-detail.module').then( m => m.VoucherDetailPageModule)
  },
  {
    path: 'trade-request-send',
    loadChildren: () => import('./trade-request-send/trade-request-send.module').then( m => m.TradeRequestSendPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'chathome',
    loadChildren: () => import('./chathome/chathome.module').then( m => m.ChathomePageModule)
  },
  {
    path: 'wishlist-voucher-detail/:restaurant_id/:type',
    loadChildren: () => import('./wishlist-voucher-detail/wishlist-voucher-detail.module').then( m => m.WishlistVoucherDetailPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'search/:type',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'restaurant-detail/:restaurant_id',
    loadChildren: () => import('./restaurant-detail/restaurant-detail.module').then( m => m.RestaurantDetailPageModule)
  },
  {
    path: 'chatroom-detail/:id',
    loadChildren: () => import('./chatroom-detail/chatroom-detail.module').then( m => m.ChatroomDetailPageModule)
  },
  {
    path: 'ownerlist/:id/:mode',

    loadChildren: () => import('./ownerlist/ownerlist.module').then( m => m.OwnerlistPageModule)
  },
  {
    path: 'quick-link/:voucher_id',
    loadChildren: () => import('./quick-link/quick-link.module').then( m => m.QuickLinkPageModule)
  },
  {
    path: 'redeem/:id',
    loadChildren: () => import('./redeem/redeem.module').then( m => m.RedeemPageModule)
  },
  {
    path: 'gift-voucher/:voucher_id',
    loadChildren: () => import('./gift-voucher/gift-voucher.module').then( m => m.GiftVoucherPageModule)
  },
  {
    path: 'banner-detail/:news_id',
    loadChildren: () => import('./banner-detail/banner-detail.module').then( m => m.BannerDetailPageModule)
  },
  {
    path: 'location',
    loadChildren: () => import('./location/location.module').then( m => m.LocationPageModule)
  },
  {
    path: "service/:location_id",
    loadChildren: () => import('./service/service.module').then( m => m.ServicePageModule)
  },
  {
    path: 'request-add',
    loadChildren: () => import('./request-add/request-add.module').then( m => m.RequestAddPageModule)
  },{
    path: 'request',
    loadChildren: () => import('./request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'request-album-modal',
    loadChildren: () => import('./request-album-modal/request-album-modal.module').then( m => m.RequestAlbumModalPageModule)
  },
  {
    path: 'service-record',
    loadChildren: () => import('./service-record/service-record.module').then( m => m.ServiceRecordPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'account-detail/:account_id',
    loadChildren: () => import('./account-detail/account-detail.module').then( m => m.AccountDetailPageModule)
  },
  {
    path: 'contact/:account_id',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'contact-detail/:contact_id',
    loadChildren: () => import('./contact-detail/contact-detail.module').then( m => m.ContactDetailPageModule)
  },
  {
    path: 'stamp',
    loadChildren: () => import('./stamp/stamp.module').then( m => m.StampPageModule)
  },
  {
    path: 'offer-detail/:offer_id',
    loadChildren: () => import('./offer-detail/offer-detail.module').then( m => m.OfferDetailPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'notification-detail/:id',
    loadChildren: () => import('./notification-detail/notification-detail.module').then( m => m.NotificationDetailPageModule)
  }



  







  // {
  //   path: 'tab1',
  //   loadChildren: () => import('./tab1/tab1.module').then( m => m.Tab1PageModule)
  // },
  // {
  //   path: 'tab2',
  //   loadChildren: () => import('./tab2/tab2.module').then( m => m.Tab2PageModule)
  // }




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
